.Characters {
    //background-color: rgba(0, 0, 0, 0.5);
    //height: 60vh;
}

input {
    color: rgb(255, 255, 255);   
    height: 34px;
    margin: 2vh 0;
    background-color: white;
    border: none;
}