.NavItem {
    margin: 0;
    width: 100%;
    height: 6vh;
    // font-size: 1.5rem;
    box-sizing: border-box;
    text-align: center;
}

.NavItem a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
}

.NavItem P {
    margin: 0;
}

.NavItem a:hover
.NavItem a:active,
.NavItem a.active {
    color: rgb(161, 131, 103);
}

@media (min-width: 600px) {
    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavItem a {
        color: white;
        height: 100%;
        // font-size: 1.5rem;
        padding: 1.6vh 1vw;
        border-bottom: 4px solid transparent;
        align-items: center;
    }
    
    .NavItem a:hover,
    .NavItem a:active,
    .NavItem a.active {
        background-color: rgb(126, 49, 250);
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}