.Shop {
    background-color: rgb(34, 26, 0);
}

a {
  text-decoration: none;
  color: white;
}
.filter {
    display: flex;
    margin: 0;
    justify-content: space-between;
}

.filter label  p {
    margin: 1vh 1vw;
    padding: 1vh 2vw;
    //border: solid white 1px;
}

.Items {
 
    flex-wrap: wrap;
   
    width: 100%;
}


.dropbtn {
    background-color: transparent;
    width: 15vw;
    margin: 0;
    padding: 1vh 2vw;
    color: white;
    border: none;
    border: solid white 1px;
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdownContent a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdownContent {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    /* background-color: #0a0814; */
  }


.box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}
.material-icons{
    font-size: 2.4em;
    cursor: pointer;
}
.card{
    width: 300px;
}
.card .card-image .card-title{
    color: black;
    font-weight: 400
}
.cart{
    margin-top:40px;
}
.collection .collection-item{
    display: flex;
}
.collection-item.avatar .title {
    font-size: 20px;
    font-weight: 500;
    color: #ee6e73;
    display: block;
    margin-bottom: 20px;
    
}
.collection .collection-item img{
    width: 150px;
    margin-right: 25px;
}
.item-desc{
    align-self:center;
}

.dualBtn {
//    display: flex;
    display: block;
    justify-content: flex-end;
    padding: 2vh 0vw;
    margin: 0;
    //width: 100%;
}

.dualGrid {
    //width: 100%;
    min-height: 7vh;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 
        "dualLeft dualRight";
}

.dualRight button {
    display: block;
}

.dualLeft {
    grid-area: dualLeft;
}
.dualRight {
    grid-area: dualRight;
}
@media only screen and (min-width: 600px) {
    /* For tablets: */
    .dualBtn {
    //    display: flex;
    //    padding: 2vh 0;
    }
    .filter {
        justify-content: left;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .dualBtn {
   //     display: flex;
     //   padding: 2vh 0;
    }
    .dualRight button {
    //    display: block;
    }

    .filter {
        justify-content: left;
    }
}
