.Auth {       
    font-family: 'Chalkduster'!important;
    padding-top: 3vh;
    text-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.5);   
}
.Auth a { 
    // display: inline-block;     
    // position: relative;    
    z-index: 1;     
    // padding: 1vh 1vw;
    // margin: 1vh 0;
    // text-emphasis: none;
    text-decoration: none;
}

.Auth p {
    padding: 0!important;
    margin-bottom: 0;
}


.AuthInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 1vh 0;
    background-color: transparent !important;
    //background-color: rgba(250, 226, 161, 0.267)  !important;
    border: none;
    border-bottom-color:rgb(23, 4, 53);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.AuthInput2 {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent !important;
    border: none;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.Btn {
    font-family: 'Chalkduster'!important;
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: .5vh 0;
//    padding: 1vh 0;
    cursor: pointer;
}

.BtnDiv {
    padding: .5em 0;
}

.AuthNav {
    width: 100%;
    display: flex;
}

.AuthToggle {
    border: none;
    width: 50%;
    background-color: transparent;
    color: rgb(23, 4, 53);
    color: rgb(255, 255, 255);   
    font-weight: bold;
    font-family: 'Chalkduster'!important;
    white-space: nowrap;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.AuthSelected {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}


#inputId:-webkit-autofill {
    background-color: black !important;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Auth {
        margin: 0 20vw 0;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Auth {
        margin: 0 30vw 0;
      
    }
}
