.Faqs {
    background-color: rgb(34, 26, 0);
}

.Faq {
    margin: 1vh 0 3vh;
}

.Faqs ul {
    //list-style-type: disc;
    list-style-type: none;
}



@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Faqs {

    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Faqs {

    }
}
