.Auth {       
    text-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 40vw;
    
}
.Auth a { 
    width: 100%;
    margin: 1vh 0;
    display: block;
    text-emphasis: none;
    text-decoration: none;
}  


.AuthInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent;
    border: none;
    border-bottom-color:rgb(23, 4, 53);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.Btn {
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: 1vh 0;
}

.BtnDiv {
    margin: 1vh 0;
}

.AuthNav {
    width: 100%;
    display: flex;
}

.AuthToggle {
    border: none;
    width: 50%;
    background-color: transparent;
    color: rgb(23, 4, 53);
    color: rgb(255, 255, 255);   
    font-weight: bold;
    white-space: nowrap;
}

.AuthSelected {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}



  @media only screen and (min-width: 600px) {
    /* For tablets: */
    .Auth {
        margin: 0 20vw 0;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Auth {
        margin: 0 30vw 0;
    }
}
