/* For mobile phones: */
.Container { 
    height: 100%;
}

.btn:hover{
    color: white;
}


.spread {
    display: flex;
    justify-content: space-between;
}

.left {
    margin-left: 2vw;
}

.Jumbotron {
    margin: 0 5vw;
    padding: 10vh 30px 20vh;
    font-size: 21px;
    font-weight: 200;
    line-height: 2.1428571435;
    color: inherit;
    background-color: rgba(20, 19, 17, 0.788);
    text-align: center;
}

.CardTitle {
    font-size: 1.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: title;
    display: flex;
}


.CardTitle h1{ 
    margin: 0;
    align-items: baseline;
}


.CardDetails {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-self: start;
    align-items: center;
    font-size: .85rem;
    margin:0;

}

.CardDescription {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: description;
}

.CardThumbnail {
    grid-area: thumbnail;
    margin: 0;
    text-align: center;
	overflow: hidden;
    text-align: center;
}
.CardThumbnail img {
    
    max-height: 12.5rem;
}
.CardThumbnail img:after  {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.Author {
    margin: 16px 0;
}


.Edit {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Edit label {
    display: block;
    color: grey;
}

.Edit button {
    border: none;
    background-color: darkcyan;
    width: 100%;
    opacity: 0.9;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
}

.Edit button:hover,
.Edit button:active {
    opacity:1;
}

.Edit button.Delete {
    background-color: darkred;
}

.Edit button.Delete:hover,
.Edit button.Delete:active {
    opacity:1;
}


:focus-visible {
    background-color: white;
}

button:focus {outline:0;}
input:focus {outline:0;}
input::placeholder{ }


.line {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

// .noselect {
//     -webkit-touch-callout: none; /* iOS Safari */
//       -webkit-user-select: none; /* Safari */
//        -khtml-user-select: none; /* Konqueror HTML */
//          -moz-user-select: none; /* Old versions of Firefox */
//           -ms-user-select: none; /* Internet Explorer/Edge */
//               user-select: none; /* Non-prefixed version, currently
//                                     supported by Chrome, Edge, Opera and Firefox */
// }



.Card {
    background-color: rgb(34, 26, 0);
    margin: 0 2vw 0;
    padding: 1vh 5vw 2vh;

}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Card {
        margin: 0 5vw 0;
        //padding: 1vh 5vw 2vh;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Card {
        margin: 0 15vw 0;
        //padding: 1vh 5vw 2vh;
    }
}