.BackgroundWrapper {
	width: 100%;
	text-align: center;
	overflow: hidden;
	text-align: center;
}

video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -90;
  transform: translateX(-50%) translateY(-50%);
 	background-color: black;
  background-size: cover;
  transition: 1s opacity;
}

.BackgroundWrapper img {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -90;
  transform: translateX(-50%) translateY(-50%);
 	background-color: black;
  background-size: cover;
  transition: 1s opacity;
}
