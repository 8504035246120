.NavItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

.NavItem {
    margin: 0;
    width: 100%;
    height: 6vh;
    // font-size: 1.5rem;
    box-sizing: border-box;
    text-align: center;
}

.NavItem {

}

.NavItem P {
    margin: 0;
}

.NavItem:hover
.NavItem:active,
.NavItem.active {
    color: rgb(161, 131, 103);
}

@media (min-width: 600px) {
    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavItem {
        color: white;
        height: 100%;
        // font-size: 1.5rem;
        padding: 1.6vh 1vw;
        border-bottom: 4px solid transparent;
        align-items: center;
        cursor: pointer;
    }
    
    .NavItem:hover,
    .NavItem:active,
    .NavItem.active {
        background-color: rgb(126, 49, 250);
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}

@media (min-width: 600px) {
    .NavItems {
        flex-flow: row;
    }
}