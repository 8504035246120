.ContactData {
    //text-align: center;
    box-shadow: 0 2px 3px #ccc;
    //border: 1px solid #eee;
    //padding: 10px;
    box-sizing: border-box;
}

.ContactData a { 
    width: 100%;
    margin: 1vh 0;
    display: block;
    text-emphasis: none;
    text-decoration: none;
}


.ContactDataInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: .5vh 0;
    padding: 0;
    text-indent: 5px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    border: none;
    border-bottom-color:rgb(23, 4, 53);
    border-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
}

.ContactDataInput2 {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent !important;
    border: none;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
} 

.ContactDataRadio {
    display: inline-flex;
    align-items: center;
} 

.Btn {
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: 1vh 0;
}

.BtnDiv {
    margin: 1vh 0;
}

.ContactDataNav {
    width: 100%;
    display: flex;
}

.ContactDataToggle {
    border: none;
    width: 50%;
    background-color: transparent;
    color: rgb(23, 4, 53);
    color: rgb(255, 255, 255);   
    font-weight: bold;
    white-space: nowrap;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.ContactDataSelected {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}


#inputId:-webkit-autofill {
    background-color: black !important;
}


  @media only screen and (min-width: 600px) {
    /* For tablets: */

}

@media only screen and (min-width: 1025px) {
    /* For desktop: */

}
