.Item {
    margin: 2vh 0;
    padding: 2vh 2vw;
    width: 100%;
    text-align: center;
    align-items: center;
}

.Item .CardImage .CardTitle{
    color: black;
    font-weight: 400
}

.MaterialIcons {
/*    font-size: 2.4em; */
    cursor: pointer;
}

.ItemDesc{
    align-self:center;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Item {
        width: 25%;
    }
}