.Item {
    padding: 1vh 0;
    display: grid;
    gap: 2vw;
    grid-template-columns: .5fr 1fr .25fr .25fr;
    grid-template-areas: 
    "img desc quant price";
}


.MaterialIcons {
/*    font-size: 2.4em; */
    cursor: pointer;
    user-select: none;
}


.CardTitle{
    font-weight: 400
}

ul {
    list-style: none;
    list-style-type: none;
}

img {
    max-height: 70px;
}

.Cart {
    margin: 0 auto;
}


.Remove {
    grid-area: del;
    display: flex;
    text-align: center;
    align-items: center;
}

.CardThumbnail {
    grid-area: img;
    display: flex;
    align-items: center;
}

.CardDescription {
    grid-area: desc;
}

.CardQuantity {
    grid-area: quant;
    display: flex;
    align-items: center;
    margin: auto;
}

.CardPrice {
    grid-area: price;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: auto;
}




@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Item {
        grid-template-columns: .5fr 1.5fr .25fr .25fr;
    }
       
    img {
        max-height: 100px;
    }
    
    

}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Item {
        grid-template-columns: 1fr 350px 1fr 1fr;
    }
       
    img {
        max-height: 130px;
    }
    

}