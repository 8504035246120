.Order {
    margin: 0 0 2vh;
    //width: 100%;
    height: 100%;
    display: block;
    padding: 2vh 2vw;
    background-color: #271e00;
}

.Order p {
    margin: 0;
    //display: inline;
    // overflow: hidden;
    word-break: break-all;
    white-space: pre-wrap !important;
}
.bigbox {
    display:block;
    //justify-content: space-evenly;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .bigbox {
        display:flex;
        justify-content: space-evenly;
    }

    .box {
        width: 50%;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .bigbox {
        display:flex;
        justify-content: space-evenly;
    }
    .box {
        width: 50%;
    }
}
