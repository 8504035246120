// @font-face {
//   font-family: 'Chalkduster';
//   src: url('./assets/fonts/Chalkduster.ttf');
// }
//.menu ul {
//  font-family: 'Chalkduster';
//}

body {
  padding-top:0; 
  margin: 0;
  font-family: 'Chalkduster' !important;
  //font-family: 'Chalkduster', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //  sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.btn { 
  width: 100%;
  padding: 1vh 1vw;
  margin: 1vh 0;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  background-color: rgb(219, 158, 43);
  cursor: pointer;
}

.btn:hover          {background-color: rgb(192, 137, 35) ;}

.auth-btn           { background-color: #6623d3;}
.my-auth-btn        { color: #6623d3;}
.auth-btn:hover     { background-color: #5b1fbd;}

.btn-primary        { background-color: #357ebd;}
.my-btn-primary     { color: #357ebd;}
.btn-primary:hover  { background-color: #2f6ea5;}

.btn-info           { background-color: #5bc0de;}
.my-btn-info        { color: #5bc0de;}
.btn-info:hover     { background-color: #50a6c0;}

.btn-danger         { background-color: #d9534f;}
.my-btn-danger      { color: #d9534f;}
.btn-danger:hover   { background-color: #cc4f4b;}

.color-orange {
  color:rgb(255, 220, 124)
}

//a ::selection       { color: white !important; }
//a :active           { color: white !important; }

input               { color: black !important; }
input:checked         { background-color: black !important; }
input::selection         { background-color: transparent !important; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}


.spread {
  display: flex;
  justify-content: space-between;
}

.text-center        { text-align: center; }
.text-right         { text-align: right; }
.text-left         { text-align: left; }

.pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 3px solid #E1E8EE;
  padding: 0;
}

option { 
  /* Whatever color  you want */
  background-color: #221a00;
  padding: 1vh 1vw;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-side {
  padding: 0 .5vw;
}

button {
  padding: 0;
}

.one-line {
  //white-space: nowrap; 
  overflow: hidden;
}

.material-icons {
  cursor: pointer;
}
